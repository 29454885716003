import "core-js";

const removeNotification = (element) => {
  element.classList.remove("visible");
  window.setTimeout(() => element.remove(), 500);
};

document.callOnReady(() => {
  document
    .querySelectorAll("#devcamp-notifications .notification")
    .forEach((element) => {
      // For some reason, `!element.classList.contains(".visible")` alone reports
      // errors rarely even on modern web browsers (like Chrome 83.x). There is no
      // rational reason, and it must be related to the user settings or custom web
      // browser extension(s).
      if (
        element.classList &&
        typeof element.classList.contains == "function" &&
        !element.classList.contains(".visible")
      ) {
        window.setTimeout(() => removeNotification(element), 8000);
        element
          .querySelector(".notification-close")
          .addEventListener("click", () => removeNotification(element));

        element.classList.add("visible");
      }
    });
});

document.callOnPageChange(() => {
  document
    .querySelectorAll("#devcamp-notifications .notification")
    .forEach((element) => element.remove());
});
